import { t } from '../../generated/i18n';
import { IS_DEFAULT_CHANNEL, PRICE_FIELD } from '../../lib/config';
import { formatPrice } from '../../lib/format';
import { FadeIn } from '../common/transitions';

const OverviewLine = ({ className = '', desc, price }) => (
  <div className={`flex justify-between ${className}`}>
    <div>{desc}</div>
    <div>{price}</div>
  </div>
);

export const RemainingForFreeShipping = ({ className = '', checkout }) => {
  const free_shipping_cost_amount = t('free_shipping_cost_amount');

  const remaining =
    checkout.shippingPrice?.gross?.amount === 0
      ? 0
      : Math.max(
          free_shipping_cost_amount -
            checkout.subtotalPrice[PRICE_FIELD]?.amount,
          0,
        );

  const rate = 100 - (100 / free_shipping_cost_amount) * remaining;

  return (
    <FadeIn
      className={className}
      as='div'
      show={!(remaining <= 0 || remaining == free_shipping_cost_amount)}
    >
      <div className='flex justify-between'>
        <div>{t('free_delivery')}</div>
        <div className='font-bold'>
          {t('noch')} {formatPrice('CHF', remaining)}
        </div>
      </div>
      <div className='relative'>
        <div className='mt-4 h-[6px] w-full bg-black opacity-10'></div>
        <div
          className='absolute top-0 z-10 h-[6px] bg-black transition-all duration-300 ease-in-out'
          style={{ width: rate + '%' }}
        ></div>
      </div>
    </FadeIn>
  );
};

const TotalsSection = ({
  checkout,
  className = '',
  subtotalOnly = false,
  review = false,
}) => {
  const currency = checkout.totalPrice.currency;

  const subtotal = checkout.lines.reduce((acc, line) => {
    return (
      acc +
      (IS_DEFAULT_CHANNEL
        ? line.undiscountedTotalPrice.amount
        : line.totalPrice[PRICE_FIELD]?.amount)
    );
  }, 0);
  const shipping = checkout.shippingPrice[PRICE_FIELD]?.amount || 0;
  const total = checkout.totalPrice[PRICE_FIELD]?.amount;
  const discount = subtotal + shipping - total;

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <OverviewLine
        desc={t('subtotal')}
        className={subtotalOnly ? 'font-bold' : ''}
        price={formatPrice(currency, subtotal)}
      />
      {!subtotalOnly && (
        <>
          <OverviewLine
            desc={t('shippingCosts')}
            price={formatPrice(currency, shipping)}
          />
          {discount > 0 ? (
            <OverviewLine
              className='text-green'
              desc={t('discount')}
              price={formatPrice(currency, -1 * discount)}
            />
          ) : null}
          <OverviewLine
            className='font-bold'
            desc={IS_DEFAULT_CHANNEL ? t('totalInclTax') : t('totalexcltax')}
            price={formatPrice(currency, total)}
          />
          {!IS_DEFAULT_CHANNEL && review ? (
            <>
              <OverviewLine
                className='font-bold border-t border-0.3 mt-4 pt-4'
                desc={t('tax')}
                price={formatPrice(currency, checkout.totalPrice.tax.amount)}
              />
              <OverviewLine
                className='font-bold'
                desc={t('totalInclTax')}
                price={formatPrice(currency, checkout.totalPrice.gross.amount)}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default TotalsSection;
